import { IconProps } from './types';

const NextIcon = ({ fill = '#262626', width = 12, height = 10 }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox='0 0 12 10' fill='#262626;' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.1233 5.46484L-0.00195312 5.46486L-0.00195312 4.46486L10.0522 4.46484L6.40587 0.81843L7.11298 0.111328L11.6484 4.64682L12.002 5.00036L11.6484 5.35391L7.11318 9.88942L6.40605 9.18234L10.1233 5.46484Z'
        fill={fill}
      />
    </svg>
  );
};

export default NextIcon;
