import { useEffect, useRef, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { H1Cursor, H2, LeadParagraph } from '../../../components/Typography/Typography.styles';
import { receiverVideoConfiguration } from '../../../layout/MainLayout/utils';
import { GreetingType } from '../../Sender/CreationWizard/types';
import { ReceiverWrapperMobile } from '../Home/Home.styles';
import { decryptString } from '../Home/utils';

const ReceiverMobile = () => {
  const [searchParam] = useSearchParams();

  const [showVideo, setShowVideo] = useState<boolean>(false);
  const message = searchParam.get('message');
  const headline = searchParam.get('headline');
  const sender = searchParam.get('sender');
  const videoUrl =
    receiverVideoConfiguration[(searchParam.get('greeting') as GreetingType) ?? GreetingType.HOLIDAY_GIFT];
  const containerRef = useRef<any>(null);
  const videoRef = useRef<any>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.style.opacity = '1';
      }

      setTimeout(() => {
        setShowVideo(true);
      }, 550);
    }, 150);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <ReceiverWrapperMobile ref={containerRef}>
        {searchParam.get('headline') && <H1Cursor>—</H1Cursor>}
        {searchParam.get('headline') && <H2>{decryptString(headline ?? '')}</H2>}
        <LeadParagraph style={{ marginTop: '2rem' }}>{decryptString(message ?? '')}</LeadParagraph>
        <LeadParagraph style={{ marginTop: '2rem', textAlign: 'right' }}>{sender}</LeadParagraph>
      </ReceiverWrapperMobile>
      {showVideo && (
        <video
          ref={videoRef}
          controls
          loop={false}
          style={{ width: '100%', zIndex: 200, marginTop: '1rem' }}
          onPlay={() => {
            if (videoRef.current) {
              if (videoRef.current.requestFullscreen) {
                videoRef.current.requestFullscreen();
              } else if (videoRef.current.webkitRequestFullscreen) {
                /* Safari */
                videoRef.current.webkitRequestFullscreen();
              } else if (videoRef.current.msRequestFullscreen) {
                /* IE11 */
                videoRef.current.msRequestFullscreen();
              }
            }
          }}>
          <source src={videoUrl} type='video/mp4' />
        </video>
      )}
    </>
  );
};

export default ReceiverMobile;
