import styled from '@emotion/styled';

import { LeadParagraph } from '../Typography/Typography.styles';

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: safe center;
  width: 50%;
  z-index: 200;
  padding-bottom: 10vw;
  @media (max-width: 768px) {
    width: 100%;
    justify-content: flex-start;
    padding-bottom: 0;
  }
  &::-webkit-scrollbar {
    position: absolute;
    left: 0;
  }
`;

export const MiniWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const ContainerLeadParagraph = styled(LeadParagraph)`
  margin-top: 3.5rem;
  white-space: break-spaces;
`;

export const ContainerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;
