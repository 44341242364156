import { useEffect } from 'react';

import { useNavigate } from 'react-router';

import { Button } from '../../../components/Button/Button.styles';
import ContentContainer from '../../../components/ContentContainer/ContentContainer';
import { BodySmallScalable, ButtonLabel } from '../../../components/Typography/Typography.styles';
import { SENDER_FLOW_ACTIVE_UNTIL } from '../../../constants/variables';
import { useAuth } from '../../../hooks/useAuth';
import { RouteNames } from '../../../routes/constants';

const Home = () => {
  const navigate = useNavigate();

  const { isLoggedIn, onLoginRedirect } = useAuth();

  useEffect(() => {
    if (SENDER_FLOW_ACTIVE_UNTIL) {
      navigate(`/${RouteNames.UNAVAILABLE}`);

      return;
    }

    if (isLoggedIn) {
      navigate(`/${RouteNames.WISH_WIZARD}`);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      navigate(`/${RouteNames.WISH_WIZARD}`);
    }
  }, [isLoggedIn]);

  return (
    <ContentContainer
      heading='Season’s Greetings'
      leadParagraph={`To send Season’s Greetings e-card please first log in to${'\n'}your ABB account`}>
      <Button variant='primary' onClick={onLoginRedirect} style={{ marginTop: '2rem', marginBottom: '3rem' }}>
        <ButtonLabel>Login</ButtonLabel>
      </Button>
      <BodySmallScalable>
        Please note that neither your message nor your recipients&apos;
        <br />
        addresses will be stored. We only keep track of the number of times
        <br />
        the e-card generator is used.
      </BodySmallScalable>
    </ContentContainer>
  );
};

export default Home;
