import { useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { WishChoiceContainer, WishChoiceValue, WishChoiceWrapper } from './GreetingChoice.styles';
import { GreetingType, WizardStepProps } from '../types';

const WishChoice = ({ wishConfiguration, setCanContinue }: WizardStepProps) => {
  const [searchParam, setSearchParams] = useSearchParams();
  const [chosenWish, setChosenWish] = useState(wishConfiguration.greetingType);
  const wishChoices = [GreetingType.HOLIDAY_GIFT, GreetingType.TOGETHERNESS, GreetingType.FESTIVE_JOURNEY];

  return (
    <WishChoiceWrapper>
      {wishChoices.map((wishChoice, index) => (
        <WishChoiceContainer
          key={wishChoice}
          wishTypeIndex={index}
          chosen={chosenWish === wishChoice}
          onClick={() => {
            setChosenWish(wishChoice);
            wishConfiguration.greetingType = wishChoice;
            setSearchParams({
              step: searchParam.get('step')?.toString() ?? '',
              wishType: wishChoice.toLowerCase().replaceAll(' ', '_'),
            });
            setCanContinue(true);
          }}>
          <WishChoiceValue chosen={chosenWish === wishChoice}>{wishChoice}</WishChoiceValue>
        </WishChoiceContainer>
      ))}
    </WishChoiceWrapper>
  );
};

export default WishChoice;
