import { GreetingType } from '../../pages/Sender/CreationWizard/types';

export const DEFAULT_VIDEO = '/videos/main_bg.mp4';

export const senderVideoConfiguration = {
  [GreetingType.HOLIDAY_GIFT]: '/videos/sender/HolidayGift_BG.mov',
  [GreetingType.TOGETHERNESS]: '/videos/sender/Togetherness_BG.mp4',
  [GreetingType.FESTIVE_JOURNEY]: '/videos/sender/FestiveJourney_BG.mp4',
};

export const receiverVideoConfiguration = {
  [GreetingType.HOLIDAY_GIFT]: '/videos/receiver/HolidayGift_BG.mp4',
  [GreetingType.TOGETHERNESS]: '/videos/receiver/Togetherness_BG.mp4',
  [GreetingType.FESTIVE_JOURNEY]: '/videos/receiver/FestiveJourney_BG.mp4',
};
