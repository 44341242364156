import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100vw - 8rem);
  height: calc(100vh - 8rem);
  background-repeat: no-repeat;
  background-position: center;
  background-position-x: right;
  background-position-y: bottom;
  background-size: cover;
  padding: 4rem;
  @media (max-width: 768px) {
    padding: 1rem;
    width: calc(100vw - 2rem);
  }
  overflow-y: auto;
`;

export const AnimationVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 300ms ease-in-out;
`;
export const ChoiceAnimationVideo = styled.video`
  position: absolute;
  width: 400px;
  height: auto;
  object-fit: contain;
  left: 58%;
  top: 20%;
  display: flex;
  flex-direction: column;
  transition: opacity 300ms ease-in-out;
  border-radius: 2px;
  box-shadow:
    rgba(0, 0, 0, 0.3) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.4) 0px 10px 10px -5px,
    black 0px 2px 4px 0px inset;
  border: 10px solid whitesmoke;
  @media (max-width: 768px) {
    position: relative;
    order: 1;
    top: 0;
    left: 0;
    width: 94%;
    height: 100%;
    object-fit: cover;
  }
`;

export const PrivacyPolicyButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: flex-start;
  z-index: 200;
  padding-bottom: 1rem;
  @media (max-width: 768px) {
    padding-bottom: 0;
    padding-top: 2rem;
  }
`;

export const Logo = styled.img`
  height: 2.25rem;
`;

export const PrivacyPolicyLink = styled(Link)`
  font-family: 'ABBVoice';
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  z-index: 100;
  padding-bottom: 1rem;
  padding-top: 1rem;
  color: #262626;
  &:visited: {
    color: #262626;
  }
  @media (min-width: 769px) {
    display: none;
  }
  @media (max-width: 768px) {
    order: 2;
    padding-bottom: 8rem;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 48px;
  @media (max-width: 768px) {
    padding-top: 62px;
  }
`;
