import styled from '@emotion/styled';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    top: -5%;
  }
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 930px;
  background-color: ${(props: any) => props.theme.colors.white};
  border-top: ${(props: any) => `2px solid ${props.theme.colors.red}`};
  padding: 2rem;
  overflow-y: auto;
  max-height: calc(80% - 10rem);
  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const WrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-items: flex-start;
  align-items: center;
`;
